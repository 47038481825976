import axios from "axios";

const prod = () => {
  return axios.create({
    // baseURL: "http://localhost:3000/api/v1/",
    baseURL: "https://udapi.diskwala.com/api/v1/",
    headers: { "auth-token": localStorage.getItem("auth-token") },
  });
};

export const APISignUp = (data, callback) => {
  prod()
    .post("/admin/signup", data)
    .then(function (response) {
      console.log(response);
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      console.log(error);
      callback({ status: 400, data: error.response.data });
    });
};

export const APILogin = (data, callback) => {
  prod()
    .post("/admin/login", data)
    .then(function (response) {
      console.log(response);
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      console.log(error);
      callback({ status: 400, data: error.response.data });
    });
};

export const auth = (callback) => {
  prod()
    .get("/admin/auth")
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

export const APIGetAdUnits = (callback) => {
  prod()
    .get("/admin/adunits")
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

export const APIUpdateAdUnits = (data, callback) => {
  prod()
    .post("/admin/update_adunits", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIUpdateCPM = (data, callback) => {
  prod()
    .post("/admin/cpm", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};
export const APIGet10CPM = (callback) => {
  prod()
    .get("/admin/cpm")
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIGetRequests = (params, callback) => {
  prod()
    .get("/admin/get_requests", { params: params })
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIGetWithdrawalByStatus = (params, callback) => {
  prod()
    .get("/admin/get_requests_by_status", { params: params })
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIUpdateRequest = (data, callback) => {
  prod()
    .post("/admin/update_request", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIGetUsers = (callback) => {
  prod()
    .get("/admin/getUsers")
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};
export const APIGetUserRevenue = (params, callback) => {
  prod()
    .get("/admin/getUserRevenue", { params: params })
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};
export const APIGetUserWithdrawal = (params, callback) => {
  prod()
    .get("/admin/getUserWithdrawal", { params: params })
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIGetUser = (params, callback) => {
  prod()
    .get("/admin/getUser", { params: params })
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIBlockUser = (params, callback) => {
  prod()
    .get("/admin/block_user", { params: params })
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};
export const APIUnBlockUser = (params, callback) => {
  prod()
    .get("/admin/unblock_user", { params: params })
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIDeleteAllVideos = (data, callback) => {
  prod()
    .post("/admin/delete_user_files", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIGetAllUserAnalytics = (callback) => {
  prod()
    .get("/admin/get_user_analytics")
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIGetDailyAnalytics = (data, callback) => {
  prod()
    .post("/admin/daily", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIGetUnverifiedUsers = (callback) => {
  prod()
    .get("/admin/unverified_account")
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIUpdateUserVerification = (data, callback) => {
  prod()
    .post("/admin/verification_action", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIDeleteFile = (data, callback) => {
  prod()
    .post("/admin/delete_file", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIGetFileDetails = (data, callback) => {
  prod()
    .post("/file/min_info", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};
