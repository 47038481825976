import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import loadable from "@loadable/component";
import Loading from "./loading";
import { auth } from "./APIServices";

const Login = loadable(() => import("./pages/login"), {
  fallback: <Loading />,
});

const Dashboard = loadable(() => import("./pages/dashboard"), {
  fallback: <Loading />,
});

const Website = loadable(() => import("./pages/website"), {
  fallback: <Loading />,
});

const SignUp = loadable(() => import("./pages/signup"), {
  fallback: <Loading />,
});

function AppRouter() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [redirectUrl, setRedirectUrl] = useState("/");

  const updateLoggedIn = () => {
    setLoading(true);
    auth((res) => {
      if (res.status === 200) {
        setLoggedIn(true);
        setUser(res.data.user);
      } else {
        handleLogout();
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    updateLoggedIn();
  }, []);

  const handleLogin = () => {
    updateLoggedIn();
  };

  const handleLogout = (url) => {
    localStorage.clear();
    setLoggedIn(false);
    setRedirectUrl(url || "/");
  };

  if (loading) {
    return <Loading />;
  } else if (loggedIn) {
    return (
      <Routes>
        <Route path="*" element={<Navigate to="/dashboard" replace={true} />} />
        <Route
          path="/dashboard"
          element={<Dashboard user={user} handleLogout={handleLogout} />}
        />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" exact element={<Website />} />
        {/* <Route path="/tnc" element={TNC} /> */}
        {/* <Route path="/privacy" element={PP} /> */}
        <Route path="/login" element={<Login handleLogin={handleLogin} />} />
        {/* <Route path="/signup" element={<SignUp />} /> */}
        <Route
          path="*"
          element={<Navigate to={redirectUrl} replace={true} />}
        />
      </Routes>
    );
  }
}

export default AppRouter;
