import React from "react";
import Typography from "@mui/material/Typography";
const Loading = () => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h5" color="primary">
        Loading...
      </Typography>
    </div>
  );
};
export default Loading;
