import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AppRouter from "./router";
import "./App.css";

const theme = createTheme({
  // props: {
  //   MuiTextField: {
  //     label: {
  //       backgroundColor: "white"
  //     },
  //     variant: 'outlined',
  //   },

  // },

  // overrides : {
  //   MuiOutlinedInput: {
  //     top: '0px'
  //   }
  // }

  typography: { fontFamily: "Nunito, sans-serif" },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#5f4dee",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
  },
});

function App() {
  return (
    <>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            autoHideDuration={2000}
            preventDuplicate
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            dense="true"
            maxSnack={3}
          >
            <Router>
              <AppRouter />
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
